/* Styles for the entire home container */
.home-container {
    padding: 20px;
    background-color: #f0f0f0;
    max-width: 100%;
    height: 1000px;
  }
  
  /* Styles for the gallery container */
  .gallery-container {
    text-align: center;
    width: 100%;
    height: 900px;
    background-color: #ffffff;
    padding: 20px;
    height: auto; /* Adjust height to auto for dynamic resizing */
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    overflow: hidden; /* Hide overflow */
  }
  
  /* General gallery styles */
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    overflow: auto; /* Allow scroll if content overflows */
    padding-bottom: 20px; /* Extra space at the bottom */
  }
  
  /* Individual gallery item styles */
  .gallery-item {
    flex: 0 1 300px; /* Adjust the flex-basis and grow/shrink settings */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  /* Image specific styles */
  .gallery-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: filter 0.3s ease;
  }

  .gallery-item img:hover {
    filter: brightness(80%);
    cursor: pointer;
}

.gallery-item.clicked {
    filter: brightness(40%);
}
  
  /* Styles for the number under the image */
  .number h3 {
    margin-top: 10px;
    font-size: 20px;
    color: #333;
  }
  
  .marked-received {
    background-color: #007bff; /* Blue color */
    color: white; /* Text color */
    text-align: center;
    padding: 10px 0; /* Adjust padding as needed */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}