/* FileUploader Styles */
.file-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 400px;
    border: 2px dashed gray;
    cursor: pointer;
    overflow: hidden;  /* Ensures the image does not overflow the boundaries of the container */
}

.file-upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#file-upload {
    display: none;
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the area without distorting the aspect ratio */
}


.create-postcard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.file-upload-container {
    margin-bottom: 20px;
}

.search-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.search-input {
    padding: 8px;
    margin-right: 10px; /* Adds space between input and button */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-button, .submit-button {
    padding: 8px 16px;
    background-color: #b3b3b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-button:hover, .submit-button:hover {
    background-color: #a6a6a6;
}

.user-details {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

