.h1{
    font-size: 100px;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333;
}

.go-back {
    background-color: #007bff; /* Blue color */
    color: white; /* Text color */
    text-align: center;
    padding: 10px 0; /* Adjust padding as needed */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

.viewPostcard-container {
    perspective: 1000px; /* Define the perspective */
}

.gallery {
    width: 100%;
}

.viewGallery {
    width: 100%;
    /* position: relative; */
    transform-style: preserve-3d; 
    transition: transform 0.5s;
}

.front,
.back {
    
    object-fit: contain;
    top: 0;
    left: 0;
    backface-visibility: hidden; /* Hide back face when flipped */
}

.front {
    height: 550px;
    /* max-width: 100%; */
    z-index: 2; /* Ensure front side is on top */
    justify-content: center; /* Align items horizontally in the center */
    display: flex;
    align-items: center;
}

.back {
    transform: rotateY(180deg); /* Initially hide back side */
    background-color: white;
    height: 550px;
    padding: 20px;
}
.back:hover{
    cursor:pointer
}

.front img {
    height: 100%;
    width: auto;
    max-width: 100%;
}

.front img:hover{
    cursor:pointer
} 

.viewGallery.flip .front {
    transform: rotateY(-180deg); /* Rotate front side */
}

.viewGallery.flip .back {
    transform: rotateY(0); /* Rotate back side */
}

.backInfo {
    flex: 1; /* Take up the available space */
    padding-right: 10px; /* Add some spacing between backInfo and verticalLine */
}
.backMessage {
    flex: 1; /* Take up the available space */
    padding-left: 10px; /* Add some spacing between verticalLine and backMessage */
}

.verticalLine {
    border-left: 1px solid black; /* Add a black solid line */
    margin: 0 10px; /* Adjust the spacing around the line */
}
.revealPostcardMsg{
    text-align: center;
    padding: 20px;
}